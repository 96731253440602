
/**
 * Index-classic-saas component
 */
import {
    MailIcon,
    UserIcon,
    KeyIcon,
    BookmarkIcon,
    UserCheckIcon,
    ArrowRightIcon
} from 'vue-feather-icons';
import Vue from 'vue';
import accountMenu from "@/views/account/menu.vue";
import infoService from "../../../services/infoService";
import { EventBus } from "../../../event-bus"
import jwt from "jsonwebtoken";
export default {
    data: () => ({
        info: {
            firstName: null,
            lastName: null,
            email: null,
            username: null,
            image: null,
        },
        show1: false,
        show2: false,
        show3: false,
        logo: "",
        coverImg: "",
        overlay: false,
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        modalEditProfile: false,
        textResult: null,
        formChangePwdValid: false,
        newLogoImg: null,
        newLogoPreview: null,
        checkSizeLogoImg: true,
        checkSizeCoverImg: true,
        msg: [],
        isValid: true,
        imageRules: [
            v => v.size > 3000000 || 'Image size should be less than 3 MB!'
        ],
    }),
    components: {
        UserIcon,
        UserCheckIcon,
        MailIcon,
        BookmarkIcon,
        KeyIcon,
        accountMenu,
        ArrowRightIcon
    },
    computed: {
        token() {
            return Vue.$cookies.get('user_token')
        },
    },
    watch: {
        newPassword(value) {
            if (value.length < 8 && value.length > 0) {
                this.msg['newpwd'] = 'กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัวขึ้นไป';
                this.isValid = false;
            } else {
                this.msg['newpwd'] = '';
                this.isValid = true;
            }
        },
        confirmPassword(value) {
            if (value.length < 8 && value.length > 0) {
                this.msg['confpwd'] = 'กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัวขึ้นไป';
                this.isValid = false;
            } else {
                this.msg['confpwd'] = '';
                this.isValid = true;
            }
        }
    },
    mounted() {
        this.getInfo();
    },
    methods: {
        openNewSite() {
            let token = Vue.$cookies.get("user_token");


            var created_time = new Date().getTime();
            var tokenJwt = jwt.sign({ token, created_time }, '1619502661');
            if (token != null) {
                let url = `https://admin.nerdof.com/login?token=${tokenJwt}`;
                window.open(url, "_blank");
            }
        },
        chooseFile() {
            this.$refs.newLogo.click()
        },
        uploadLogo() {
            if (this.newLogoImg != null) {
                let fd = new FormData();
                fd.append("fileupload", this.newLogoImg)

                this.overlay = true
                infoService.updateLogo(fd).then((resp) => {
                    if (resp.status == 200 && resp.data.status) {
                        this.cancelUpload();
                        this.getInfo()
                    } else {
                        this.$bvModal.msgBoxOk(
                            resp.data.message || "Can not update logo due to something went wrong.",
                            {
                                title: "Error",
                                centered: true,
                            }
                        )
                    }

                }).catch((err) => {
                    this.overlay = false
                    this.$bvModal.msgBoxOk(
                        "Can not update logo due to something went wrong.",
                        {
                            title: "Error",
                            centered: true,
                        }
                    )
                })
            }
        },
        cancelUpload() {
            this.newLogoImg = null
            this.newLogoPreview = null
        },
        onNewLogoChange(e) {
            if (e.target.files.length > 0) {
                this.newLogoImg = e.target.files[0]
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.newLogoPreview = event.target.result;
                };
                reader.readAsDataURL(this.newLogoImg);
                this.uploadLogo();
            } else {
                this.newLogoImg = null
                this.newLogoPreview = null
            }

        },

        getInfo() {
            this.overlay = true
            infoService
                .getInfo()
                .then((resp) => {
                    if (resp.data.status == true && resp.data.result != null) {
                        let info = resp.data.result.info;

                        this.info.firstName = info.firstName;
                        this.info.lastName = info.lastName;
                        this.info.email = info.companies[0].contactEmail;
                        this.info.username = info.username;
                        this.info.image = info.image || info.companies[0].coverImg;
                        this.info.domain = info.companies[0].domainName + '.nerdof.com'
                        this.info.companies = info.companies[0]
                        this.logo = info.companies[0].logo ? info.companies[0].logo : "/images/no-picture.png"
                        this.coverImg = info.companies[0].coverImg ? info.companies[0].coverImg : "/images/no-picture.png"

                        this.overlay = false
                    } else {
                        throw "Can not get information user";
                    }
                })
                .catch((err) => {

                }).finally(() => {
                    this.overlay = false
                });
        },
        onChangeCoverImg(e) {
            if (e.target.files.length > 0) {
                if (e.target.files[0].size < 3000000) {
                    this.checkSizeCoverImg = true
                    this.coverImg = e.target.files[0]
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        this.newLogoPreview = event.target.result;
                    };
                    reader.readAsDataURL(this.coverImg);
                    this.uploadLogoCoverImg();
                }
                else {
                    this.checkSizeCoverImg = false
                }
            } else {
                this.coverImg = null
                this.newLogoPreview = null
            }

        },
        uploadLogoCoverImg() {
            if (this.coverImg != null) {
                let fd = new FormData();
                fd.append("fileupload", this.coverImg)

                this.overlay = true
                infoService.uploadImageCover(fd).then((resp) => {
                    if (resp.status == 200 && resp.data.status) {
                        this.cancelUpload();
                        this.getInfo()
                    } else {
                        this.$bvModal.msgBoxOk(
                            resp.data.message || "Can not update logo due to something went wrong.",
                            {
                                title: "Error",
                                centered: true,
                            }
                        )
                    }

                }).catch((err) => {
                    this.overlay = false
                    this.$bvModal.msgBoxOk(
                        "Can not update logo due to something went wrong.",
                        {
                            title: "Error",
                            centered: true,
                        }
                    )
                })
            }
        },
        onChangeLogoImg(e) {
            if (e.target.files.length > 0) {
                if (e.target.files[0].size < 3000000) {
                    this.checkSizeLogoImg = true
                    this.logo = e.target.files[0]
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        this.newLogoPreview = event.target.result;
                    };
                    reader.readAsDataURL(this.logo);
                    this.uploadLogoImg();
                }
                else {
                    this.checkSizeLogoImg = false

                }

            } else {
                this.logo = null
                this.newLogoPreview = null
            }

        },
        uploadLogoImg() {
            if (this.logo != null) {
                let fd = new FormData();
                fd.append("fileupload", this.logo)

                this.overlay = true
                infoService.uploadImageLogo(fd).then((resp) => {
                    if (resp.status == 200 && resp.data.status) {
                        this.cancelUpload();
                        this.getInfo()
                    } else {
                        this.$bvModal.msgBoxOk(
                            resp.data.message || "Can not update logo due to something went wrong.",
                            {
                                title: "Error",
                                centered: true,
                            }
                        )
                    }

                }).catch((err) => {
                    this.overlay = false
                    this.$bvModal.msgBoxOk(
                        "Can not update logo due to something went wrong.",
                        {
                            title: "Error",
                            centered: true,
                        }
                    )
                })
            }
        },
        editProfile() {
            this.overlay = true
            infoService
                .editProfile(this.info)
                .then((data) => {
                    // this.modalEditProfile = true
                    // this.textResult = "บันทึกสำเร็จ"
                    this.$bvModal.msgBoxOk('บันทึกสำเร็จ', {
                        title: "Status",
                        size: "sm",
                        okVariant: "success",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                })
                .catch((err) => {
                    // this.modalEditProfile = true
                    // this.textResult = "บันทึกไม่สำเร็จ"
                    this.$bvModal.msgBoxOk('บันทึกไม่สำเร็จ', {
                        title: "Status",
                        size: "sm",
                        okVariant: "danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                }).finally(() => {
                    this.overlay = false
                });
        },
        onChangePassword() {
            // check has new password
            if (this.newPassword != "" && this.newPassword != null) {
                if (this.oldPassword == "" || this.oldPassword == null) {
                    return this.$bvModal.msgBoxOk(
                        "Please specific old password.",
                        {
                            title: "Required Old Password",
                            centered: true,
                        }
                    )
                }

                if (this.newPassword != this.confirmPassword) {
                    return this.$bvModal.msgBoxOk(
                        "New password doesn't match with confirm password, please try again.",
                        {
                            title: "New Password Doesn't Match",
                            centered: true,
                        }
                    )
                }

                // call service
                this.overlay = true
                infoService.changePassword({
                    oldPwd: this.oldPassword,
                    newPwd: this.newPassword
                }).then((resp) => {
                    // alert
                    this.$bvModal.msgBoxOk(
                        "You have changed password, please login again.",
                        {
                            title: "Successfull",
                            centered: true,
                        }
                    ).then(() => {
                        // logout and force to login
                        EventBus.$emit("signOut");
                        this.$router.replace({
                            path: "/login"
                        }).catch(err => {
                            // do nothing
                        })
                    })

                }).catch((err) => {
                    this.$bvModal.msgBoxOk(
                        "Old password you have entered doesn't match.",
                        {
                            title: "Old Password Doesn't Match",
                            centered: true,
                        }
                    )
                }).finally(() => {
                    this.overlay = false
                })
            } else {
                // do nothing
            }
        }
    },
};
